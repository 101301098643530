<template>
  <div class="landing-page">
    <header class="header">
      <div id="header-bg"></div>

      <h1 class="title">The <span class="gradient-text">AI Receptionist</span> that automates scheduling over<br />
        <span :class="channel_name == 1 ? 'text-visible' : 'text-hidden'"><span
            class="gradient-text">Email</span>.</span>
        <span :class="channel_name == 2 ? 'text-visible' : 'text-hidden'"><span
            class="gradient-text">WhatsApp</span>.</span>
        <span :class="channel_name == 3 ? 'text-visible' : 'text-hidden'"><span class="gradient-text">SMS</span>.</span>
      </h1>
      <p id="subheading">Empower your customers to instantly book, manage appointments and get answers through
        Email, WhatsApp and SMS.
        <br /><br />
      <form>
        <input type="email" v-model="email" placeholder="Enter email address" required />
        <button @click.prevent="requestDemo">Request a demo</button>
      </form>
      </p>
    </header>

    <section>
      <img v-if="mobile" src="@/assets/how-it-works.gif" style="width: 100%; max-width: 600px; display: block; margin: auto; margin-bottom: 80px;" alt="">
      <h1 class="hidden">BookedSolid - Simplified Scheduling, Smarter Business</h1>
      <p class="hidden delay-1">Welcome to BookedSolid - the AI receptionist that connects to your business' Email,
        WhatsApp, or SMS and
        automatically responds to your clients to schedule
        appointments, manage bookings, answer questions and more. <br /><br />We provide a full suite of booking tools as well
        as integrating with platforms like Cliniko and SimplyBook.me to work with your existing booking system.
      </p>
      <img v-if="!mobile" src="@/assets/how-it-works.gif" style="width: 100%; max-width: 600px; display: block; margin: auto" alt="">


      <a href="https://dashboard.bookedsolid.co.uk/login?show=register" class="cta">Go to App</a>
    </section>

    <section id="why-choose-us">
      <div class="two-columns">
        <div>
          <h1 class="hidden">Why Use BookedSolid?</h1>
          <p class="hidden delay-1">No more lost bookings during holidays or late-night inquiries. Our AI chat assistant
            will engage with your
            customers 24/7, handling appointment requests, answering questions, and even sending payment links—all while
            you're off the clock.
          </p>
        </div>
        <img src="@/assets/screenshot1.gif" alt="Demo of the BookedSolid AI" />
      </div>
    </section>

    <section id="platform">
      <div class="two-columns right">
        <div>
          <h1 class="hidden">Automate Your Messages</h1>
          <p class="hidden delay-1">Let our AI handle inquiries and appointment scheduling. Customers get instant
            responses, saving you time and ensuring seamless communication - no matter when they reach out...</p>
        </div>
        <img src="@/assets/customer-phrases.gif" alt="Customer Phrases" />
      </div>
    </section>

    <section id="integrations">
      <div class="two-columns">
        <div>
          <h1 class="hidden">Integrations</h1>
          <p class="hidden delay-1">BookedSolid integrates with a range of popular booking systems, including Cliniko,
            SimplyBook.me and more.
            Our AI can work with your existing booking system to provide a seamless experience for your clients.
          </p>
        </div>
        <img src="@/assets/screenshot3.png" alt="BookedSolid's Integrations" />
      </div>
    </section>

    <section id="case-study">
      <div class="two-columns right">
        <div>
          <h1 class="hidden">Case Study<span style="font-size: 0.8em"> - GYMPODS</span></h1>
          <p><b>"BookedSolid have made it so easy for our customers to book our PODs and they've been a pleasure to work with from the start!"</b> - Mike Crockett, CEO</p>
        </div>
        <img src="@/assets/gympods-demo.gif" alt="Case Study" />
        <!-- <video width="100%" autoplay loop>
            <source src="@/assets/gympods-demo.mp4" type="video/mp4">
            Your browser does not support the video tag.
          </video> -->
      </div>
      <div id="bg"></div>
    </section>

    <section id="why-choose-us">
      <h1 class="hidden">Why Choose BookedSolid?</h1>
      <ul class="hidden delay-1">
        <li><b>Save Time:</b> Let our AI assistant take over tedious tasks like managing schedules and replying to
          customers, freeing you up to focus on your business.</li>
        <li><b>Boost Your Business:</b> Never miss a booking opportunity again—our AI works 24/7, so you don't have to.
        </li>
        <li><b>Easy Setup:</b> Whether you're a tech-savvy professional or a business owner with limited tech skills,
          our tool is simple to set up and even easier to use.</li>
        <li><b>Grow Customer Satisfaction:</b> Provide a seamless experience for your clients by making booking fast,
          flexible, and convenient.</li>
      </ul>

      <a href="https://dashboard.bookedsolid.co.uk/login?show=register" class="cta">Get Started</a>
    </section>

    <div id="gettingStarted">
      <h1 class="hidden">Start Streamlining Your Business Today</h1>

      <form class="hidden delay-1">
        <h2>Tell us about your business</h2>
        <div v-if="!formLoaded" class="loader"></div>
        <div v-if="formLoaded" class="form-group">
          <input type="text" id="name" name="name" v-model="name" placeholder="Name" required>
        </div>
        <div v-if="formLoaded" class="form-group">
          <input type="email" id="email" name="email" v-model="email" placeholder="Email" required>
        </div>
        <div v-if="formLoaded" class="form-group">
          <select type="text" id="company" name="company" v-model="company" required>
            <option value="" disabled selected>Business type</option>
            <option value="fitness">Fitness</option>
            <option value="health">Health/Medical</option>
            <option value="beauty">Beauty/Wellness</option>
            <option value="education">Eductional/Driving Lessons</option>
            <option value="events">Events/Entertainment</option>
            <option value="retail">Retail</option>
            <option value="other">Other</option>
          </select>
        </div>
        <div v-if="formLoaded" class="form-group">
          <textarea id="message" name="message" rows="5" v-model="message" placeholder="Message" required></textarea>
        </div>
        <button v-if="formLoaded" type="submit" class="submit-button" @click.prevent="contact">Send</button>
      </form>
    </div>
  </div>
</template>

<script>
export default {
  name: 'LandingPage',
  data() {
    return {
      name: '',
      email: '',
      company: '',
      message: '',
      formLoaded: false,
      clicked: false,
      lastScroll: 0,
      channel_name: 1,
      mobile: window.innerWidth < 925,
    };
  },
  methods: {
    async contact() {
      if (this.name == "" || this.email == "" || this.issue == "" || this.more == "") {
        alert("Please fill out all fields.");
        return;
      } else {
        fetch(
          `https://docs.google.com/forms/d/e/1FAIpQLSeXtGPjBlW161jYbac9fpS__GT4Nv9B3Vq4n-6iAkRr_HOGOQ/formResponse?usp=pp_url&entry.1039398541=${this.name}&entry.1981332653=${this.email}&entry.859721265=${this.company}&entry.645716167=${this.message}`
        ).catch(() => { });
        this.name = "";
        this.email = "";
        this.company = "";
        this.message = "";
        alert("Thank you for contacting us. We will get back to you shortly.");
      }
    },
    async requestDemo() {
      if (this.email == "") {
        alert("Please enter your email address.");
        return;
      } else {
        fetch(
          `https://docs.google.com/forms/d/e/1FAIpQLSeXtGPjBlW161jYbac9fpS__GT4Nv9B3Vq4n-6iAkRr_HOGOQ/formResponse?usp=pp_url&entry.1039398541=${this.name}&entry.1981332653=${this.email}&entry.859721265=${this.company}&entry.645716167=${this.message}`
        ).catch(() => { });
        this.email = "";
        alert("Thank you for requesting a demo. We will get back to you shortly.");
      }
    },
    async openSite(url) {
      window.open(url, '_blank');
    },
    handleClick() {
      this.clicked = true;
    },
    handleScroll() {
      if (document.getElementById('carousel').scrollLeft < this.lastScroll) this.clicked = true;
    },
    handleInterval() {
      if (this.channel_name == 3) this.channel_name = 1;
      else this.channel_name++;
    },
    updateMobile() {
      this.mobile = window.innerWidth < 925;
    },
  },
  mounted() {
    setTimeout(() => {
      this.formLoaded = true;
    }, 500);

    const observer = new IntersectionObserver((entries) => {
      entries.forEach(entry => {
        if (entry.isIntersecting) {
          entry.target.classList.add('show');
        }
      });
    });

    document.querySelectorAll('.hidden').forEach(el => {
      observer.observe(el);
    });

    setInterval(this.handleInterval, 1500);
    window.addEventListener('resize', this.updateMobile);
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.updateMobile);
  },
};
</script>

<style lang="scss" scoped>
:root {
  /* Height of the cut*/
  --cut: 100px;
}

.hidden {
  opacity: 0;
  transition: all 1s;
}

.slide-in {
  transform: translateX(-200px);
}

.slide-in-right {
  transform: translateX(200px);
}

@media (prefers-reduced-motion) {
  .hidden {
    opacity: 1;
  }
}

.show {
  opacity: 1;
  transform: translateX(0);
}

.delay-1 {
  transition-delay: 0.2s !important;
}

.delay-2 {
  transition-delay: 0.6s !important;
}

p,
li {
  font-size: 19px;
  color: #666;
}

.landing-page {
  text-align: center;
  padding: 40px;
}

.gradient-text {
  background: linear-gradient(to right, #2596be, #2562be);
  -webkit-background-clip: text;
  background-clip: text;
  color: transparent;
}

.text-visible {
  position: absolute;
  opacity: 1;
  transition: opacity 0.6s ease-in-out;
}

.text-hidden {
  position: absolute;
  opacity: 0;
  transition: opacity 0.3s ease-in-out;
}

.header {
  margin-bottom: 30px;
  margin-top: 60px;
  min-height: calc(100vh - 190px);
  text-align: left;

  #header-bg {
    position: absolute;
    top: 0;
    left: 0;
    width: 100vw;
    height: 120vh;
    z-index: -1;
    // background-color: #1e7a9c2c;
    background: radial-gradient(ellipse at 60% 45%, #2562becc, white 60%);
  }

  h1 {
    font-size: 3.7rem;
    font-weight: bold;
    margin: 20px auto 40px;
    width: 80vw;
    max-width: 800px;
    text-transform: none;
    // color: white;
  }

  #subheading {
    font-size: 1.5rem;
    width: 80vw;
    max-width: 800px;
    margin: 0 auto;
    margin-top: 100px;
    color: #2c3e50;

    // button {
    //   display: inline-block;
    //   padding: 20px 30px;
    //   font-size: 16px;
    //   font-weight: bold;
    //   letter-spacing: 0.7px;
    //   text-decoration: none;
    //   background-color: #2562be;
    //   border: none;
    //   border-radius: 8px;
    //   color: #fff;
    //   transition: background-color 0.3s ease;
    //   cursor: pointer;

    //   a {
    //     color: white;
    //     text-decoration: none;

    //     i {
    //       margin-left: 10px;
    //     }
    //   }

    //   &:hover {
    //     background-color: #1e7b9c;
    //   }
    // }
  }

  form {
    display: flex;
    justify-content: left;
    align-items: center;
    margin-top: 40px;

    input {
      padding: 10px;
      height: 50px;
      width: 40%;
      font-size: 16px !important;
      color: inherit;
      border: 1px solid #2562be;
      border-radius: 4px 0 0 4px;
      border-right: none;
      box-sizing: border-box;
      background: none;
      font: inherit;
    }

    button {
      font-size: 16px;
      padding: 10px 20px;
      height: 50px;
      font-weight: bold;
      text-decoration: none;
      background: linear-gradient(to left, #2562be, #2562be);
      color: #fff;
      border: none;
      border-radius: 0 4px 4px 0;
      transition: all 0.3s ease;
      cursor: pointer;

      &:hover {
        background: linear-gradient(to top left, #2596be, #2562be);
      }
    }
  }
}

section {
  position: relative;
  width: 80vw;
  max-width: 1100px;
  margin: 150px auto;
  text-align: left;

  .two-columns {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: relative;

    div {
      width: 45%;
    }

    img, video {
      width: 45%;
    }

    &.right {
      flex-direction: row-reverse;
    }
  }

  h1 {
    font-size: 2.4rem;
    font-weight: bold;
    margin-bottom: 10px;
    text-transform: none;
    background: linear-gradient(to right, #2596be, #2562be);
    -webkit-background-clip: text;
    background-clip: text;
    color: transparent;
  }

  p {
    font-size: 1.2rem;
    margin-bottom: 40px;
    line-height: 1.8rem;
    letter-spacing: 0.6px;

    &.portrait {
      max-width: 500px;
    }
  }

  ol,
  ul {
    text-align: left;
    margin: 0 auto;
    padding: 0;
    max-width: 800px;
    margin-top: 40px;

    li {
      font-size: 1.2rem;
      margin-bottom: 20px;
      line-height: 1.8rem;
    }
  }

  .cta {
    display: block;
    margin: 50px auto;
    width: 100px;
    text-align: center;
    padding: 20px 30px;
    font-size: 16px;
    font-weight: bold;
    letter-spacing: 0.7px;
    text-decoration: none;
    background-color: #2562be;
    border: none;
    border-radius: 8px;
    color: #fff;
    transition: background-color 0.3s ease;
    cursor: pointer;

    a {
      color: white;
      text-decoration: none;

      i {
        margin-left: 10px;
      }
    }

    &:hover {
      background-color: #1e7b9c;
    }
  }
}

#case-study {
  h1 {
    color: white;
    background: none;
  }

  p {
    color: #E1E3E5;
  }

  #bg {
    background: #2f9ebc;
    width: 1000vw;
    position: absolute;
    height: 52vw;
    max-height: 700px;
    top: -40px;
    left: -1000px;
    z-index: -1;
  }
}

#gettingStarted {
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
  background-color: #1e7a9c2c;
  width: 100vw;
  padding: 100px 0;
  margin-left: -40px;
  margin-bottom: -40px;

  h1 {
    font-size: 2.7rem;
    font-weight: bold;
    margin-bottom: 50px;
    margin-top: 0;
    text-transform: none;
  }

  form {
    margin: auto;
    background: #fcfcfc;
    padding: 40px;
    border-radius: 15px;
    border: solid 1px #e6e6e6;
    text-align: left;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);

    h2 {
      margin: 0;
      margin-bottom: 20px;
    }

    label {
      font-size: 18px;
      color: #333;
    }

    input,
    select,
    textarea {
      width: 100%;
      padding: 10px;
      font-size: 16px;
      color: inherit;
      border: 1px solid #ccc;
      border-radius: 4px;
      box-sizing: border-box;
      margin-bottom: 10px;
      font: inherit;
    }

    textarea {
      resize: vertical;
    }

    .submit-button {
      display: inline-block;
      padding: 8px 50px;
      font-size: 18px;
      font-weight: bold;
      text-decoration: none;
      background-color: #2562be;
      color: #fff;
      border: none;
      transition: background-color 0.3s ease;
      cursor: pointer;

      &:hover {
        background-color: #1e7b9c;
      }
    }
  }
}

@-webkit-keyframes spin {
  0% {
    -webkit-transform: rotate(0deg);
  }

  100% {
    -webkit-transform: rotate(360deg);
  }
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

.loader {
  border: 4px solid #f3f3f3;
  border-radius: 50%;
  border-top: 4px solid #1e7b9c;
  width: 40px;
  height: 40px;
  -webkit-animation: spin 1.5s linear infinite;
  animation: spin 1.5s linear infinite;
  margin: auto;
}

@media (max-width: 925px) {
  .landing-page {
    padding: 40px 20px;
  }

  .header {
    margin-top: 20px;
    min-height: calc(100vh - 140px);

    .title {
      font-size: 10vw;
    }

    #header-bg {
      height: 100vh;
      background: radial-gradient(ellipse at 60% 50%, rgba(37, 98, 190, 0.7), white 60%);
    }

    form {
      flex-direction: column;
      margin-top: 20px;

      input,
      button {
        width: 100%;
        border-radius: 4px;
      }

      input {
        border: solid 1px #2562be;
        margin-bottom: 10px;
      }
    }
  }

  ul,
  ol {
    width: 90%;
  }

  #gettingStarted {
    margin-left: -20px;

    h1 {
      font-size: 8vw;
    }

    form {
      padding: 40px 20px;
      margin: 20px;
    }
  }

  section {
    width: 90vw;
    margin: auto;
    margin-bottom: 120px;

    h1 {
      font-size: 8vw;
    }

    .two-columns {
      flex-direction: column;

      &.right {
        flex-direction: column;
      }

      div {
        width: 100%;
      }

      img {
        width: 100%;
      }
    }
  }

  #case-study {
    #bg {
      height: 400px;
    }
    video, img {
      width: 100vw;
    }
  }
}
</style>
