<template>
  <header v-if="!mobile" class="header">
    <div class="logo">
      <router-link to="/">
        <img src="../assets/name.png" alt="Logo" />
      </router-link>
    </div>
    <nav class="navigation">
      <ul class="menu">
        <li>
          <a href="/home/#how-it-works">How it Works</a>
        </li>
        <li>
          <a href="/home/#integrations">Integrations</a>
        </li>
        <li>
          <a href="/#case-study">Case Study</a>
        </li>
      </ul>
    </nav>
    <div class="cta-buttons">
      <a href="https://dashboard.bookedsolid.co.uk/login?show=register" class="cta-button button">Go to App <i style="font-size: 0.7em" class="fas fa-external-link"></i></a>
      <router-link to="/book-demo" class="cta-button button-main">Book a Call</router-link>
    </div>
  </header>

  <header v-else class="mobile-header">
    <div id="mobile-nav-bg" :class="{ 'active': this.mobileNav }"></div>
    <i class="fas fa-bars" :class="{ 'active': this.mobileNav == false }" id="openNav" @click="toggleNav"></i>
    <i class="fas fa-times" :class="{ 'active': this.mobileNav == true }" id="closeNav" @click="toggleNav"></i>
    <div v-if="!this.mobileNav" class="logo">
      <router-link to="/">
        <img src="../assets/name.png" alt="Logo" />
      </router-link>
    </div>
    <div v-else class="logo">
      <router-link @click="toggleNav" to="/">
        <img src="../assets/name-inverted.png" alt="Logo" />
      </router-link>
    </div>
    <nav class="mobile-navigation" :class="this.mobileNav ? 'nav-open' : 'nav-closed'">
      <ul class="menu">
        <li>
          <a @click="this.mobileNav = false" href="/#why-choose-us">Why Choose Us?</a>
        </li>
        <li>
          <a @click="this.mobileNav = false" href="/#how-it-works">How it Works</a>
        </li>
        <li>
          <a @click="this.mobileNav = false" href="/#integrations">Integrations</a>
        </li>
        <li>
          <router-link @click="this.mobileNav = false" to="/pricing">Pricing</router-link>
        </li>
        <li>
          <router-link @click="this.mobileNav = false" to="/contact">Contact</router-link>
        </li>
        <li>
          <a @click="this.mobileNav = false" href="https://dashboard.bookedsolid.co.uk/login?show=register"
            class="cta-button">Get Started</a>
        </li>
      </ul>
    </nav>
    <span v-if="this.mobileNav === true" id="navTint" @click="toggleNav"></span>
  </header>
</template>

<script>
export default {
  name: 'Header',
  data() {
    return {
      mobile: window.innerWidth < 1000 ? true : false,
      mobileNav: false,
    };
  },
  methods: {
    toggleNav() {
      this.mobileNav = !this.mobileNav;
    },
  },
  mounted() {
    window.addEventListener('resize', () => {
      this.mobile = window.innerWidth < 1000 ? true : false;
    });
  },
};
</script>

<style lang="scss" scoped>
.header {
  display: flex;
  justify-content: space-around;
  align-items: center;
  padding: 20px;
  position: sticky;
}

.logo {
  // flex: 1;
}

.logo img {
  width: 80vw;
  max-width: 200px;
}

.navigation {
  // flex: 2;
  text-align: center;
}

.menu {
  list-style: none;
  padding: 0;
  margin: 0;
}

.menu li {
  display: inline-block;
  margin-right: 30px;
  font-weight: bold;
  font-family: Avenir, Helvetica, Arial, sans-serif;
  color: #2c3e50;
  transition: all 0.2s ease;

  a {
    font-size: 1.2rem;
    color: #2c3e50;
    text-decoration: none;
  }

  .sub-menu {
    position: absolute;
    background-color: white;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    padding: 10px;
    margin-top: 10px;
    border-radius: 5px;
    
    z-index: 2;
    text-align: left;

    li {
      display: block;
      margin: 10px 0;
      
      a {
        font-size: 1.2em;
      }
    }
  }

  &:last-child {
    margin-right: 0;
  }

  &:hover {
    color: #2562be;
    text-decoration: underline;
  }
}

.cta-buttons {
  // flex: 3;
  text-align: right;
}

.cta-button {
  display: inline-block;
  margin: 10px;
  font-size: 1.1rem;
  font-weight: bold;
  text-decoration: none;
  color: #2c3e50;
  transition: opacity 0.3s ease;

  &.button {
    font-weight: normal;
    margin-right: 20px;
  }

  &.button-main {
    border: solid 3px;
    border-radius: 5px;
    padding: 12px 20px;
  }

  &:hover {
    opacity: 0.7;
  }
}

/* Mobile styling */
@media (max-width: 1000px) {
  img {
    // margin-left: 30px;
    margin-top: 15px;
    position: relative;
    z-index: 2;
  }

  .menu li a {
    color: white;
    padding: 20px;
    display: block;
    font-size: 20px;
    font-weight: bold;
  }

  .menu li a:first-child {
    padding-top: 30px;
  }

  .mobile-navigation {
    width: 0px;
    height: calc(100vh - 80px);
    overflow-x: hidden;
    position: fixed;
    top: 80px;
    left: 0;
    transition: 0.2s;
    background-color: #2596be;
    color: white;
    text-align: left;
    display: flex;
    flex-direction: column;
    white-space: nowrap;
  }

  ul {
    display: flex;
    flex-direction: column;
  }

  .nav-open {
    width: 300px;
    z-index: 3;
  }

  #openNav,
  #closeNav {
    position: absolute;
    top: 0;
    left: 0;
    font-size: 30px;
    padding: 25px;
    width: 30px;
    height: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 100px;
    opacity: 0;
    rotate: 90deg;
    transition: 0.2s;
    cursor: pointer;
    color: #333;
  }

  #closeNav {
    rotate: -90deg;
    color: white !important;
  }

  #openNav.active,
  #closeNav.active {
    z-index: 3;
    opacity: 1;
    rotate: 0deg;
  }

  #navTint {
    width: 100vw;
    height: calc(100vh - 80px);
    position: fixed;
    top: 80px;
    left: 0;
    z-index: 2;
    background-color: rgba(0, 0, 0, 0.5);
  }

  #mobile-nav-bg {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 80px;
    transition: 0.2s;
    z-index: -1;
    background-color: white;
  }

  #mobile-nav-bg.active {
    background-color: #2596be;
    z-index: 1;
  }
}
</style>
